


























































import fileDownload from 'js-file-download'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import api, { ApiResponse } from '../../../../../../api'
import Dispatching from '../../../../../../store/models/Dispatching'
export default Vue.extend({
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: 'Nom du groupe',
          field: 'group.name',
          searchable: true
        },
        {
          label: 'Nom du bénéficiaire',
          field: 'beneficiary.name',
          searchable: true
        },
        {
          label: 'Numéro de téléphone',
          field: 'beneficiary.phone'
        },
        {
          label: 'Montant Total Dû du groupe',
          field: 'deadline.dueAmount',
          currency: true
        },
        {
          label: "Date de l'écheance",
          field: 'deadlineDate',
          date: true
        }
      ],
      reimbursements: []
    }
  },
  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    dispatching() {
      return Dispatching.find(this.$route.params.dispatchingId)
    }
  },

  methods: {
    proceed() {
      this.$router.push({
        name: 'finances.refunds.consignations.chargeReimbFile',
        params: {
          dispatchingId: this.$route.params.dispatchingId
        }
      })
    },
    async generateFile(this: any) {
      this.isLoading = true
      const url = `/reimbursement-file/generate/${this.$route.params.dispatchingId}`
      api
        .request({
          method: 'GET',
          url,
          responseType: 'blob'
        })
        .then(response => {
          fileDownload(
            response.data,
            `${this.dispatching.reference}_reimbursement_file.xlsx`,
            response.headers['content-type']
          )
          this.isLoading = false
          return response
        })
    }
  },
  async created(this: any) {
    console.log(this.$route.params.dispatchingId)

    await api
      .get(`/reimbursement-file/${this.$route.params.dispatchingId}/`)
      .then(res => {
        const data: any[] = []
        res.data.map((manualReimbursementInfo: any) => {
          for (const beneficiary of manualReimbursementInfo.groupBeneficiaries) {
            data.push({
              group: manualReimbursementInfo.group,
              beneficiary: beneficiary,
              deadline: manualReimbursementInfo.deadline
            })
          }
        })
        this.reimbursements = data
      })
    if (!this.dispatching) {
      Dispatching.api().fetch()
    }
  }
})
